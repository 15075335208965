<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(ELEMENT_NAME + '.search.error.fetching_data')}}
      </h4>
    </b-alert>
    <template v-if="elementData">
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
        <element-info-card 
            :element-data="elementData" 
            :element-name="ELEMENT_NAME" 
            :son-type="SON_TYPE" 
            :parent-id="PARENT_ID"
            />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BButton, BAlert, BLink,
} from 'bootstrap-vue'
import ElementStoreModule from '../storeModule'
import ElementInfoCard from './InfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BLink,
    // Local Components
    ElementInfoCard,
  },
  data() {
    return {
      elementData: {},
      ELEMENT_APP_STORE_MODULE_NAME: '',
      ELEMENT_NAME: '',
      SON_TYPE: undefined,
      PARENT_ID: undefined
    }
  },
  mounted() {
    this.ELEMENT_NAME = router.currentRoute.params.type
    this.SON_TYPE = (router.currentRoute.params.son_type && router.currentRoute.params.son_type != 'undefined') ? router.currentRoute.params.son_type : ''
    this.PARENT_ID = router.currentRoute.params.parent_id ? router.currentRoute.params.parent_id.toString() : ''
    this.ELEMENT_APP_STORE_MODULE_NAME = 'app-ent-v-' + this.ELEMENT_NAME
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    this.fetchElement()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    fetchElement() {
      store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_element', { id: router.currentRoute.params.id })
      .then(response => { 
        console.info(response)
        this.elementData = response.data 
        console.info(this.elementData)
      })
      .catch(error => {
        if (error.response.status === 404) {
          console.info('404', this.elementData)
          this.elementData = undefined
        }
      })
    },
  }
}
</script>

<style>

</style>
